import { isObaEnabled, isWaltersonLoginEnabled } from 'src/features/PortalData';
import { TEST_ORIGIN } from 'src/utils/dev';

import type { PortalConfigData } from 'src/features/PortalData';

/**
 * Generate the Walterson Service origin from the provided origin
 *
 * @private
 * @param origin The origin to convert
 * @returns The Walterson origin
 */
export const getWaltersonApiHost = (origin: string = TEST_ORIGIN): string =>
    origin.includes('localhost')
        ? origin
        : origin
              .split('//')
              .map((a, i) => (i === 1 ? `id.${a.replace('www.', '')}` : a))
              .join('//');

export const shouldShowObaScreen = (config: PortalConfigData): boolean => isObaEnabled(config);

export const shouldUseWaltersonLogin = (config: PortalConfigData): boolean => isWaltersonLoginEnabled(config);
