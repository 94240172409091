export const SERVER_ERROR = {
    SYSTEM: 'system',
    BELOW_13: 'at least 13 years old',
    USR_NOT_FOUND: 'user not found',
    INTERNAL_SERVER_ERROR: 'internal server error',
    USERNAME_TAKEN: 'choose another username',
    EMAIL_TAKEN: 'email already used',
    ACCOUNT_LOCK: 'account locked',
    AUTO_PHONE_CALL_FAIL: 'automated phone code error',
    TXT_CODE_ERR: 'text code error',
    MULTI_ACCNT: 'multiple accounts',
    LDAP_LOCK: 'account locked - LDAP',
    RBA_DISABLE: 'account disabled - RBA',
    RBA_LOCK: 'account locked - RBA',
    UNAUTHENTICATED: 'unauthenticated',
    ACCT_NOT_FOUND: 'account not found',
    TOKEN_EXPIRED: 'expired token',
    INCORRECT_TEXT_CODE: 'incorrect text code',
    INCORRECT_PHONE_CODE: 'incorrect phone call code',
    INCORRECT_SQ: 'incorrect security question answer',
    PHONE_INVALID: 'phone number is not valid',
    SQA_INVALID: 'security question answer is not valid',
    TFA_DATA_INVALID: 'error while entering information',
    USER_REJECTED_DURING_RISK_ASSESSMENT: 'user has been rejected during risk assessment',
} as const;

export const FIELD_ERROR = {
    ERROR_MENU: 'Error menu',
    FIELD_VALIDATION: 'Field validation Error',
} as const;

export const LINK_FRIENDLY_NAMES = {
    // common
    LEARN_MORE: 'healthsafe id',
    PRIVACY_POLICY: '[PAGE_NAME]:privacy policy',
    TERMS_OF_USE: '[PAGE_NAME]:terms of use',
    TRUSTED_DEVICE: '[PAGE_NAME]:trusted device',
    CONSUMER_NOTICE: '[PAGE_NAME]:consumer communication notice',
    TEXTING_TERMS: '[PAGE_NAME]:texting terms and conditions',
    CALL_AGAIN: 'call me again',
    CALL_INSTEAD: 'call me instead',
    TEXT_AGAIN: 'text me again',
    TEXT_INSTEAD: 'text me instead',
    EDIT: 'edit',
    // View All Answers Link
    VIEW_ALL_ANSWER: '[PAGE_NAME]:View All Answers',
    VIEW_ALL_ANSWER_UHC: '[PAGE_NAME]:View All Answers',
    // register
    FORGOT_USERNAME: '[PAGE_NAME]:forgot username',
    FORGOT_PASSWORD: '[PAGE_NAME]:forgot password',
    REGISTER: '[PAGE_NAME]:register',
    LOGIN: '[PAGE_NAME]:signin',
    REGISTER_NOW: '[PAGE_NAME]:register now',
    UNLOCK_ACCOUNT: 'unlock account',
    TRY_REGISTERING: 'try registering',
    HELP_ICON: '[PAGE_NAME]:help',
    REGISTER_HELP: '[PAGE_NAME]:registration help',
    SIGNIN_HELP: '[PAGE_NAME]:signin help', // TODO kept for testing not sure as it is not mentioned in document
    ACCESSIBILITY_INFORMATION: '[PAGE_NAME]:accessibility statement for individuals with disabilities',
    ACCESSIBILITY_UHC_INFORMATION: 'accessibility statement for individuals with disabilities',
    STEP1_CONTINUE_BTN: 'personalInfo:continue',
    STEP1_UHC_CONTINUE_BTN: 'personal information:continue',
    COPPA_CONTINUE_BTN: 'parents and legal guardians:[BTN_TRIGGER]',
    COPPA_UHC_CONTINUE_BTN: 'parents and legal guardians:[BTN_TRIGGER]',
    STEP2_CONTINUE_BTN: 'step 2:set up account:continue',
    STEP2_UHC_CONTINUE_BTN: 'create account:continue',
    STEP3_CALL_ME: 'keep your account safe:phone call:call me',
    STEP3_UHC_CALL_ME: 'choose identity confirmation method:call me',
    STEP3_TEXT_ME: 'keep your account safe:text message:text me',
    STEP3_UHC_TEXT_ME: 'choose identity confirmation method:text me',
    STEP3_SQA: 'keep your account safe:security questions:continue',
    STEP3_UHC_SQA: 'choose identity confirmation method:continue',
    UPDATE_EMAIL: 'update email address:[BTN_TRIGGER]',
    UPDATE_UHC_EMAIL: 'update email:[BTN_TRIGGER]',
    UPDATE_EMAIL_CANCEL: 'update email address:cancel',
    UPDATE_UHC_EMAIL_CANCEL: 'update email:cancel',
    UPDATE_PHONE_CANCEL: 'update phone number:cancel',
    UPDATE_UHC_PHONE_CANCEL: 'update phone number:cancel',
    UPDATE_UHC_MOBILE_CANCEL: 'update mobile number:cancel',
    UPDATE_PHONE: 'update phone number:[BTN_TRIGGER]',
    UPDATE_UHC_PHONE: 'update phone number:[BTN_TRIGGER]',
    UPDATE_UHC_MOBILE: 'update mobile number:[BTN_TRIGGER]',
    STEP4_TEXT_ME: 'check your text message:create account',
    STEP4_UHC_TEXT_ME: 'enter security code from text:create account',
    STEP4_CALL_ME: 'we are calling you:create account',
    STEP4_UHC_CALL_ME: 'enter security code from call:create account',
    CALL_ME_AGAIN: 'phone call : we are calling you:call me again',
    CALL_ME_UHC_AGAIN: 'enter security code from call:call me again',
    GO_TO_DASHBOARD: '[PAGE_NAME]:continue to Dashboard',
    GO_TO_DASHBOARD_UHC: '[PAGE_NAME]:continue to Dashboard',
    ASSISTED_REGISTRATION_SKIP_LINK: '[PAGE_NAME]:skip registration',
    ASSISTED_REGISTRATION_UHC_SKIP_LINK: 'skip registration',
    // sign-in
    // SIGININ_CONTINUE: "signin:sign in",
    SIGININ_UHC_CONTINUE: 'sign in',
    SIGININ_FROM_FLOW: '[PAGE_NAME]:sign in',
    SIGININ_UHC_FROM_FLOW: '[PAGE_NAME]:sign in',
    SIGNIN: '[PAGE_NAME]:hsid sign in [RM_STATUS]',
    SIGNIN_LINK_CLOUD: '[PAGE_NAME]:sign in:link cloud',
    // verify login
    VERIFY_TEXT_CONTINUE: 'confirm identity via text:text me',
    VERIFY_TEXT_UHC_CONTINUE: 'confirm identity via text:text me',
    VERIFY_CALLME_CONTINUE: 'confirm identity via call:call me',
    VERIFY_CALLME_UHC_CONTINUE: 'confirm identity via call:call me',
    VERIFY_EMAIL_CONTINUE: 'verify:email me', // TODO Dummy Data
    VERIFY_EMAIL_UHC_CONTINUE: 'verify information:email me', // TODO Dummy Data
    VERIFY_TEXTMSG_CODE: 'check your text message:confirm', // TODO Dummy Data
    VERIFY_TEXTMSG_UHC_CODE: 'enter security code from text:confirm', // TODO Dummy Data
    VERIFY_CALL_CODE: 'check your phone call:confirm',
    VERIFY_CALL_UHC_CODE: 'enter security code from call:confirm',
    // full step up
    FULLSTEPUP_CONTINUE_BTN: 'personalInfo:continue',
    FULLSTEPUP_UHC_CONTINUE_BTN: 'personal information:continue',
    FULLSTEPUP_SKIP_BTN: 'personalInfo:skip',
    FULLSTEPUP_SKIP_BTN_UHC: 'personal information:skip',
    // Reset Password
    STEP1_RESETPWD_CONTINUE_BTN: 'user name:continue',
    STEP1_RESETPWD_UHC_CONTINUE_BTN: 'identity provide username:continue',
    STEP2_RESETPWD_TXT_CONTINUE_BTN: 'confirm your phone number:text me:text me clicked',
    STEP2_RESETPWD_TXT_UHC_CONTINUE_BTN: 'confirm identity via text:text me',
    STEP2_RESETPWD_CALL_CONTINUE_BTN: 'confirm your phone number:call me',
    STEP2_RESETPWD_CALL_UHC_CONTINUE_BTN: 'confirm identity via call:call me',
    STEP2_RESETPWD_SQ_CONTINUE_BTN: 'confirm your security question:continue',
    STEP2_RESETPWD_SQ_UHC_CONTINUE_BTN: 'confirm identity via security question:continue',
    STEP3_RESETPWD_CONFIRMPHONE_CONTINUE_BTN: 'confirm your phone number:text me confirmation:confirm',
    STEP3_RESETPWD_CONFIRMPHONE_UHC_CONTINUE_BTN: 'enter security code from text:confirm',
    STEP3_RESETPWD_CONFIRM_CALL_CONTINUE_BTN: 'confirm your phone number:we are calling you:confirm',
    STEP3_RESETPWD_CONFIRM_CALL_UHC_CONTINUE_BTN: 'enter security code from call:confirm',
    STEP3_RESETPWD_CONTINUE_BTN: 'reset password:reset password',
    STEP3_RESETPWD_UHC_CONTINUE_BTN: 'create new password:reset password',
    STEP4_RESETPWD_CONTINUE_BTN: 'reset password:reset password',
    STEP4_RESETPWD_UHC_CONTINUE_BTN: 'create new password:reset password',
    // forgot username
    STEP1_RECUSERNAME_CONTINUE_BTN: 'personalInfo:continue',
    STEP1_RECUSERNAME_UHC_CONTINUE_BTN: 'username:continue',
    // sign in and security settings
    SETTINGS_EDIT_PASSWORD: '[PAGE_NAME]:edit password',
    SETTINGS_EDIT_PASSWORD_UHC: 'edit password',
    SETTINGS_EDIT_USERNAME: '[PAGE_NAME]:edit username',
    SETTINGS_EDIT_USERNAME_UHC: 'edit username',
    SETTINGS_EDIT_EMAIL: '[PAGE_NAME]:edit email',
    SETTINGS_EDIT_EMAIL_UHC: 'edit email',
    SETTINGS_EDIT_VERIFICATION_METHOD: '[PAGE_NAME]:edit verification method',
    SETTINGS_EDIT_VERIFICATION_METHOD_UHC: 'edit verification method',
    SETTINGS_RESEND_EMAIL: '[PAGE_NAME]:send email verification',
    SETTINGS_RESEND_EMAIL_UHC: 'send email verification',
    SETTINGS_EDIT_PAGE_FLOW_SAVE: '[PAGE_NAME]:save',
    SETTINGS_EDIT_PAGE_FLOW_CANCEL: '[PAGE_NAME]:cancel',
    SETTINGS_EDIT_SAVE: 'save',
    SETTINGS_EDIT_CANCEL: 'cancel',
    SETTINGS_EDIT_VERIFICATION_TEXT: '[PAGE_NAME]:text me',
    SETTINGS_EDIT_VERIFICATION_TEXT_UHC: 'text me',
    SETTINGS_EDIT_VERIFICATION_TEXT_AGAIN: '[PAGE_NAME]:text me again',
    SETTINGS_EDIT_VERIFICATION_TEXT_AGAIN_UHC: 'text me again',
    SETTINGS_EDIT_VERIFICATION_CALL_AGAIN: '[PAGE_NAME]:call me again',
    SETTINGS_EDIT_VERIFICATION_CALL_AGAIN_UHC: 'call me again',
    SETTINGS_EDIT_VERIFICATION_TEXT_INSTEAD: '[PAGE_NAME]:text me instead',
    SETTINGS_EDIT_VERIFICATION_TEXT_INSTEAD_UHC: 'text me instead',
    SETTINGS_EDIT_VERIFICATION_CALL: '[PAGE_NAME]:call me',
    SETTINGS_EDIT_VERIFICATION_CALL_UHC: 'call me',
    SETTINGS_EDIT_VERIFICATION_CALL_INSTEAD: '[PAGE_NAME]:call me instead',
    SETTINGS_EDIT_VERIFICATION_CALL_INSTEAD_UHC: 'call me instead',
    SETTINGS_EDIT_VERIFICATION_SUBMIT: '[PAGE_NAME]:confirm',
    SETTINGS_EDIT_VERIFICATION_SUBMIT_TEXT_UHC: 'text me confirmation',
    SETTINGS_EDIT_VERIFICATION_SUBMIT_CALL_UHC: 'call me confirmation',
    // ensure and evaluate flows
    OBA_SQ_CONTINUE: 'confirm your identity:security questions:continue',
    OBA_SQ_UHC_CONTINUE: 'confirm your identity via security questions:continue',
    OBA_TEXT_CONFIRM: 'confirm your identity:sms verification:confirm',
    OBA_TEXT_UHC_CONFIRM: 'enter security code from text:confirm',
    OBA_PHONE_CONFIRM: 'confirm your identity:phone verification:confirm',
    OBA_PHONE_UHC_CONFIRM: 'enter security code on call:confirm',
    OBA_TEXT: '[PAGE_NAME]:text me',
    OBA_TEXT_UHC: 'text me',
    OBA_TEXT_AGAIN: '[PAGE_NAME]:text me again',
    OBA_TEXT_AGAIN_UHC: 'text me again',
    OBA_CALL_AGAIN: '[PAGE_NAME]:call me again',
    OBA_CALL_AGAIN_UHC: 'call me again',
    OBA_TEXT_INSTEAD: '[PAGE_NAME]:text me instead',
    OBA_TEXT_INSTEAD_UHC: 'text me instead',
    OBA_CALL: '[PAGE_NAME]:call me',
    OBA_CALL_UHC: 'call me',
    OBA_CALL_INSTEAD: '[PAGE_NAME]:call me instead',
    OBA_CALL_INSTEAD_UHC: 'call me instead',

    // footer
    FOOTER_EXTRA_LINK_TEMPLATE: '[PAGE_NAME]:[EXTRA_LINK_TEXT]',
    UHC_FOOTER_EXTRA_LINK_TEMPLATE: '[EXTRA_LINK_TEXT]',

    // click to call
    CLICK_TO_CALL_STEP1_CLOSE_MODAL: '[PAGE_NAME]:click to call step 1:[CLOSE_SOURCE]',
    CLICK_TO_CALL_STEP1_CLOSE_MODAL_UHC: 'click to call step 1:[CLOSE_SOURCE]',
    CLICK_TO_CALL_STEP2_CLOSE_MODAL: '[PAGE_NAME]:click to call step 2:[CLOSE_SOURCE]',
    CLICK_TO_CALL_STEP2_CLOSE_MODAL_UHC: 'click to call step 2:[CLOSE_SOURCE]',
    CLICK_TO_CALL_STEP3_CLOSE_MODAL: '[PAGE_NAME]:click to call step 3:[CLOSE_SOURCE]',
    CLICK_TO_CALL_STEP3_CLOSE_MODAL_UHC: 'click to call step 3:[CLOSE_SOURCE]',
    CLICK_TO_CALL_ERROR_STEP_CLOSE_MODAL: '[PAGE_NAME]:click to call step error:[CLOSE_SOURCE]',
    CLICK_TO_CALL_ERROR_STEP_CLOSE_MODAL_UHC: 'click to call step error:[CLOSE_SOURCE]',
    CLICK_TO_CALL_REQUEST_CALL_BACK_BTN: '[PAGE_NAME]:request a call back',
    CLICK_TO_CALL_REQUEST_CALL_BACK_BTN_UHC: 'request a call back',
    CLICK_TO_CALL_CALL_ME_BTN: '[PAGE_NAME]:click to call step 2:call me',
    CLICK_TO_CALL_CALL_ME_BTN_UHC: 'click to call step 2:call me',
    CLICK_TO_CALL_TRY_AGAIN_BTN: '[PAGE_NAME]:click to call error:try again',
    CLICK_TO_CALL_TRY_AGAIN_BTN_UHC: 'click to call error:try again',
    CLICK_TO_CALL_NEED_HELP_CLOSE_BTN: '[PAGE_NAME]:click to call error:close',
    CLICK_TO_CALL_NEED_HELP_CLOSE_BTN_UHC: 'click to call error:close',

    // m&r shopper
    BASE_ACCOUNT_STEP1_NEXT_BTN: 'create login credentials:continue',
    GO_TO_ACCOUNT: '[PAGE_NAME]:continue to account',
    GO_TO_ACCOUNT_UHC: 'continue to account',
    BASE_ACCOUNT_WE_ARE_CALLING_YOU_CONFIRM: '[PAGE_NAME]:confirm',
    BASE_ACCOUNT_WE_ARE_CALLING_YOU__UHC_CONFIRM: 'enter security code on call:confirm',
    BASE_ACCOUNT_CHECK_TEXT_MESSAGE_CONFIRM: '[PAGE_NAME]:confirm',
    BASE_ACCOUNT_CHECK_TEXT_MESSAGE_UHC_CONFIRM: 'enter security code from text:confirm',
    BASE_ACCOUNT_LETS_VERIFY_YOUR_EMAIL_CONFIRM: '[PAGE_NAME]:confirm',
    BASE_ACCOUNT_LETS_VERIFY_YOUR_EMAIL_UHC_CONFIRM: 'enter security code from email:confirm',
    BASE_ACCOUNT_CALL_ME_AGAIN: '[PAGE_NAME]:call me again',
    BASE_ACCOUNT_TEXT_ME_AGAIN: '[PAGE_NAME]:text me again',
    BASE_ACCOUNT_CALL_ME_INSTEAD: '[PAGE_NAME]:call me instead',
    BASE_ACCOUNT_TEXT_ME_INSTEAD: '[PAGE_NAME]:text me instead',
    BASE_ACCOUNT_UHC_TEXT_ME_AGAIN: 'enter security code from text:text me again',
    BASE_ACCOUNT_UHC_CALL_ME_AGAIN: 'enter security code on call: call me again',
    BASE_ACCOUNT_UHC_CALL_ME_INSTEAD: 'enter security code on call: call me instead',
    BASE_ACCOUNT_UHC_TEXT_ME_INSTEAD: 'enter security code from text: text me instead',

    // Language Selector
    LANGUAGE_SELECTOR_MENU_BUTTON: '[PAGE_NAME]:[ACTION] langauge selector:menu button',
    LANGUAGE_SELECTOR_MENU_BUTTON_UHC: '[ACTION] langauge selector:menu button',
    LANGUAGE_SELECTOR_EXTERNAL: '[PAGE_NAME]:hide langauge selector:external',
    LANGUAGE_SELECTOR_EXTERNAL_UHC: 'hide langauge selector:external',
    LANGUAGE_SELECTOR_MENU_ITEM: '[PAGE_NAME]:language selector:change langauge:from [PREV_LANG] to [NEXT_LANG]',
    LANGUAGE_SELECTOR_MENU_ITEM_UHC: 'language selector:change language:from [PREV_LANG] to [NEXT_LANG]',

    // Email Confirmation
    VERIFY_EMAIL_CONFIRMATION_MOBILE_SUCCESS: 'email confirmation:success:sign in using mobile app',
    VERIFY_EMAIL_CONFIRMATION_MOBILE_FAILURE: 'email confirmation:fail:sign in using mobile app',

    // WebChat
    WEB_CHAT: '[PAGE_NAME]:web chat',

    // Dashboard
    DASHBOARD_EDIT_ACCOUNT_SETTINGS: '[PAGE_NAME]:edit account settings',
    DASHBOARD_EDIT_ACCOUNT_SETTINGS_UHC: undefined,
    PORTAL_LINK_CLICK_NAME: '[PAGE_NAME]:[EXTRA_LINK_NAME]',
    PORTAL_LINK_CLICK_NAME_UHC: undefined,

    // No Access
    NO_ACCESS_RESTART_LINK: '[PAGE_NAME]:restart process',
    NO_ACCESS_RESTART_LINK_UHC: 'restart process',

    // FTR
    FTR_CALL_ME: '[PAGE_NAME]:call me',
    FTR_CALL_ME_UHC: '[PAGE_NAME]:call me',
    FTR_TEXT_ME: '[PAGE_NAME]:text me',
    FTR_TEXT_ME_UHC: '[PAGE_NAME]:text me',

    FTR_CALL_ME_CHALLENGE: '[PAGE_NAME]:we are calling you',
    FTR_CALL_ME_CHALLENGE_UHC: '[PAGE_NAME]:enter security code from call',
    FTR_TEXT_CHALLENGE_ME: '[PAGE_NAME]:check your text messages',
    FTR_TEXT_CHALLENGE_ME_UHC: '[PAGE_NAME]:enter security code from text',
} as const;
