import React, { useRef } from 'react';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';
import { IMaskInput } from 'react-imask';

import noop from 'src/utils/noop';
import { useToggleState, useComponentApiDef, useEffectOnMounted, useConstant } from 'src/hooks';

import { withContent } from 'src/components/ContentProvider';
import format from 'src/utils/format';
import compose from 'src/utils/compose';
import { CheckIcon, LockIcon } from 'src/UI/Icon';
import { noopValidator, ValidationFailure } from 'src/utils/validation';
import { useMilestones } from 'src/hooks/useMilestones';

import type { FC, ReactElement } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { PortalContentData } from 'src/features/PortalData/types';
import type { ComponentApi } from 'src/hooks';

import { RememberedUsername } from 'src/features/Authentication/types';
import identity from 'src/utils/identity';
import FieldLabel from './FieldLabel';
import {
    useFieldState,
    FieldState,
    doesFieldHaveValue,
    getFieldValue,
    isFieldTouched,
    isFieldValid,
    getFieldErrors,
} from './hooks/useFieldState';
import { CrossButton, EyeButton } from '../Button';
import { defaultRenderError, getFieldErrorId, getFieldValidId, triggerChangeEvent } from './utils';
import { getFirstFixedPartOfMask } from './getFirstFixedPartOfMask';
import classes from './InputField.module.scss';

import type { RenderErrorData } from './utils';
import type { BaseFormFieldProps } from './types';

/**
 * Define this component's public API type
 */
export interface InputFieldApi extends ComponentApi {
    setFieldValue: (newValue: string) => void;
    focus: () => void;
    setFieldErrors: (errors: FieldState['errors']) => void;
    setFieldError: (error: string | FieldState['errors'][number]) => void;
    resetField: () => void;
}

type InputFieldPublicApiProps<ErrorData = string> = {
    type?: 'text' | 'password' | 'url' | 'email' | 'date' | 'tel';
    inputMode?: 'text' | 'numeric' | 'tel' | 'email' | 'url';
    autoCapitalize?: 'off' | 'on';
    autoComplete?:
        | 'off'
        | 'username'
        | 'new-password'
        | 'current-password'
        | 'one-time-code'
        | 'tel'
        | 'email'
        | 'given-name'
        | 'family-name';
    mask?: string;
    maskedUsername?: RememberedUsername;
    maskChar?: string;
    placeholder?: string;
    /**
     * Controls how the error should be constructed from the FieldState and fieldId. This is how to control the
     * complete appearance of the field's error message. By default, it shows the first validation error as a FieldError
     *
     * @var renderError
     */
    renderError?: (errorData: RenderErrorData) => ReactElement | null;
    filter?: (value: string) => string;
    // Dev props
    devShowValid?: boolean;
    maxLength?: number;
    subLabel?: string;
} & BaseFormFieldProps<HTMLInputElement, ErrorData> &
    EmptyComponent;

interface InputFieldContentProps {
    validMessage: string;
}

const mapContentToProps = (
    { LblInputValid }: PortalContentData,
    { label }: InputFieldPublicApiProps
): InputFieldContentProps => ({
    validMessage: format(LblInputValid, {
        FIELD_NAME: label,
    }),
});

export type InputFieldProps = InputFieldPublicApiProps & InputFieldContentProps;

const isPasswordFieldType = (type: InputFieldProps['type']): boolean => type === 'password';

const isDateFieldType = (type: InputFieldProps['type']): boolean => type === 'date';

/**
 * The InputField is the base component for text-style <input> elements (e.g. `type`= text, password, url, email, tel).
 */
const InputField: FC<InputFieldProps> = ({
    name,
    label,
    subLabel,
    type = 'text',
    defaultValue = '',
    labelVariant = 'above',
    id,
    wasFormSubmitted = false,
    validate = noopValidator as NonNullable<InputFieldPublicApiProps<typeof defaultValue>['validate']>,
    onChange = noop,
    onValueChange = noop,
    onFocus = noop,
    onBlur = noop,
    onValidityChange = noop,
    filter = identity,
    autoComplete = 'off',
    autoCapitalize = 'on',
    inputMode = 'text',
    mask,
    maskChar,
    maskedUsername,
    placeholder,
    required = false,
    readOnly = false,
    api: setApi,
    renderError = defaultRenderError,
    // Dev props
    devShowValid = false,
    'aria-describedby': describedBy,
    maxLength,
}) => {
    const fieldId = id ?? name;
    const fieldErrorId = getFieldErrorId(fieldId);
    const fieldValidId = getFieldValidId(fieldId);

    const initialValue = useConstant(defaultValue);
    const fieldRef = useRef<HTMLInputElement>(null);
    const fieldControlFocusedRef = useRef<boolean>(false);
    const [fieldState, { setFieldValue, setFieldErrors, clearFieldErrors, fieldWasTouched }] = useFieldState(
        FieldState({ value: initialValue })
    );
    // Determining whether the field has been "touched" involves tracking multiple pieces of stateful data so we use
    // milestones to track those different goals until we get to the field being "touched."
    const [{ blurred: fieldWasBlurred, changed: fieldWasChanged }, { reset: resetTouchedState }] = useMilestones(
        { blurred: true, changed: true },
        () => {
            fieldWasTouched();
        }
    );
    // State to track when the `<input>` element itself is currently focused or the field controls are in use to manage visibility of
    // field controls
    const [isFieldElFocused, { on: focusFieldEl, off: blurFieldEl }] = useToggleState(false);
    const [isFieldUsed, { on: fieldIsUsed, off: fieldNotUsed }] = useToggleState(false);

    const [shouldDisplayPasswordText, { toggle: togglePasswordTextVisibility }] = useToggleState(false);

    const shouldShowFieldControls = isFieldUsed && doesFieldHaveValue(fieldState);

    const isValid = devShowValid;

    const useMaskedUsername = maskedUsername && maskedUsername.maskedValue;

    const commonProps = {
        name,
        id: fieldId,
        type: (isPasswordFieldType(type) && shouldDisplayPasswordText) || isDateFieldType(type) || mask ? 'text' : type,
        className: classNames(classes.field, 'has-rds-pv-8 has-rds-ph-16'),
        autoComplete,
        autoCapitalize,
        inputMode,
        placeholder: useMaskedUsername ? maskedUsername?.maskedValue : placeholder,
        maxLength,
        'aria-required': required,
        'aria-describedby': classNames(fieldErrorId, {
            [fieldValidId]: isValid,
            ...(typeof describedBy === 'string' ? { [describedBy]: true } : {}),
        }),
        value: getFieldValue(fieldState),
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
            fieldIsUsed();
            focusFieldEl();
            onFocus(e);
        },
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            if (!(fieldControlFocusedRef.current || e.currentTarget?.parentElement?.contains(e.relatedTarget))) {
                fieldNotUsed();
            }

            fieldControlFocusedRef.current = false;
            blurFieldEl();
            fieldWasBlurred();
            onBlur(e);
        },
        readOnly,
    };

    const checkValidationState = (newValue: string) => {
        // Updated value validation
        const validationFailures = validate(newValue);
        // Check to see if the validation state is different from the last change
        if (!deepEqual(getFieldErrors(fieldState), validationFailures)) {
            if (validationFailures.length === 0) {
                // If there's no validation failure, clear the error
                clearFieldErrors();
                onValidityChange([], false);
            } else {
                // If the validation failure has changed, set that and broadcast up
                // setFieldError(firstError);
                setFieldErrors(validationFailures);
                onValidityChange(validationFailures, false);
            }
        }
    };

    useComponentApiDef<InputFieldApi>(
        {
            setFieldValue(newValue) {
                // Programmatically trigger a change event instead of setting the state manually in order to trigger all
                // the validation and internal state updates that way. Any parent components tracking the field value
                // should call this API function and update their state through the `onChange` prop handler rather than
                // calling this AND setting their state at the same time. This maintains unidirectional flow.
                if (fieldRef.current) {
                    triggerChangeEvent(fieldRef.current, newValue);
                }
            },
            focus() {
                (fieldRef.current as HTMLInputElement).focus();
            },
            setFieldError(error) {
                const newError =
                    typeof error === 'string'
                        ? ValidationFailure({ reason: error, meta: { field: name, label } })
                        : error;
                setFieldErrors([newError]);
            },
            setFieldErrors(validationFailures) {
                setFieldErrors(validationFailures);
            },
            resetField() {
                resetTouchedState();
                if (fieldRef.current) {
                    triggerChangeEvent(fieldRef.current, initialValue);
                }
                checkValidationState(initialValue);
            },
        },
        setApi,
        name
    );

    useEffectOnMounted(() => {
        // Handle initial validation
        const initialValidationFailures = validate(getFieldValue(fieldState));

        // If we got a validation failure from initial validation, set it and communicate up
        if (initialValidationFailures.length > 0) {
            setFieldErrors(initialValidationFailures);
            onValidityChange(initialValidationFailures, true);
        }
    });

    const handlePasswordControlMouseDown = () => {
        fieldControlFocusedRef.current = true;
    };

    return (
        <div id={`${fieldId}_container`}>
            <div className="has-rds-mb-8">
                <FieldLabel
                    fieldId={fieldId}
                    className={classes.label}
                    isHidden={labelVariant === 'hidden'}
                    subLabel={subLabel}
                >
                    {label}
                </FieldLabel>
            </div>

            <div
                className={classNames(classes.fieldWrapper, 'is-flex', {
                    [classes.isFocused]: isFieldElFocused,
                    [classes.isError]:
                        !isFieldUsed && (wasFormSubmitted || isFieldTouched(fieldState)) && !isFieldValid(fieldState),
                    [classes.maskedUsername]: useMaskedUsername,
                })}
            >
                {mask ? (
                    <IMaskInput
                        mask={mask}
                        placeholderChar={maskChar}
                        lazy={!maskChar}
                        validate={(value: string) => {
                            const firstFixedPartOfMask = getFirstFixedPartOfMask(mask);
                            return !(firstFixedPartOfMask && value === firstFixedPartOfMask);
                        }}
                        onAccept={value => {
                            const valueString = value as string;
                            const newValue = filter(valueString);
                            setFieldValue(newValue);
                            checkValidationState(newValue);
                            fieldWasChanged();
                            // TODO Figure out how to take the InputEvent that is the third arg for onAccept and make it consistent with the onValueChange and onChange handlers that receive the ChangeEvent
                            onValueChange(newValue, name);
                        }}
                        inputRef={fieldRef}
                        {...commonProps}
                    />
                ) : (
                    <input
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            const { value, name: fieldName } = ev.target;
                            const newValue = filter(value);
                            setFieldValue(newValue);
                            checkValidationState(newValue);
                            fieldWasChanged();
                            onValueChange(newValue, fieldName, ev);
                            onChange(ev);
                        }}
                        ref={fieldRef}
                        {...commonProps}
                    />
                )}

                {shouldShowFieldControls ? (
                    <div className={`${classes.fieldControls} is-flex has-rds-pr-16`}>
                        {readOnly ? (
                            <span className={`${classes.fieldControlWrapper} is-flex`}>
                                <LockIcon />
                            </span>
                        ) : null}

                        {!readOnly ? (
                            <span className={`${classes.fieldControlWrapper} is-flex`}>
                                <CrossButton
                                    id={`${fieldId}-clear-action`}
                                    label={label}
                                    onMouseDown={handlePasswordControlMouseDown}
                                    onClick={() => {
                                        if (fieldRef.current) {
                                            triggerChangeEvent(fieldRef.current, '');
                                            fieldRef.current.focus();
                                        }
                                    }}
                                />
                            </span>
                        ) : null}

                        {isPasswordFieldType(type) ? (
                            <span className={`${classes.fieldControlWrapper} is-flex`}>
                                <EyeButton
                                    isToggled={shouldDisplayPasswordText}
                                    label={label}
                                    onMouseDown={handlePasswordControlMouseDown}
                                    onClick={() => {
                                        togglePasswordTextVisibility();
                                    }}
                                />
                            </span>
                        ) : null}
                    </div>
                ) : null}
                <div aria-live="polite" className={classes.validMessage}>
                    {isValid ? (
                        <div id={fieldValidId}>
                            <CheckIcon fill="green" />

                            <span className="sr-only">{`${label} is valid`}</span>
                        </div>
                    ) : null}
                </div>
            </div>

            <div id={fieldErrorId}>{renderError({ fieldState, fieldId, wasFormSubmitted })}</div>
        </div>
    );
};

export { InputField };
// TODO Remove manual typing when `compose` and HOC type modification code is in place
export default compose(withContent(mapContentToProps))(InputField) as FC<
    Omit<InputFieldProps, keyof InputFieldContentProps>
>;
