import { AikyamClient, WaltersonClient } from 'src/features/Clients';

import { loadScript } from 'src/utils/html';
import type { AxiosResponse } from 'axios';

import { logger } from 'src/app/Logger';
import { seconds } from 'src/utils/dateTimeUtility';
import type {
    AttestationResponse,
    AuthenticateRequest,
    AuthenticateResponse,
    AuthenticatorResponse,
    ChallengeRequest,
    ChallengeResponse,
    PhoneAttestationRequest,
    QuestionAttestationRequest,
    AuthenticatorByNamersRequest,
    ChallengeRequestParams,
    ProfilingMetadataRequestParams,
    ProfilingMetadataResponse,
    AikyamTmxInfoRequestParams,
    AikyamTmxInfoResponseData,
} from './types';

export type RBAProfilingStatus = 'PASSED' | 'SCRIPT_LOAD_TIMED_OUT' | 'PROFILING_TIMED_OUT' | 'ERRORED';

export type RBAProfilingResult = { status: RBAProfilingStatus; error?: Error };

/** Walterson User Edge Service */
const OBAService = {
    /** Login to user account and provision IS cookie. */
    authenticate: (data: AuthenticateRequest, useAikyamEndpoint = false): Promise<AuthenticateResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<AuthenticateResponse>('/rest/public/account/v2/authenticate', data)
            .then(response => response.data),

    /** Request authenticators associated with logged-in user. */
    getAuthenticators: (useAikyamEndpoint = false): Promise<AuthenticatorResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .get<AuthenticatorResponse>('/rest/protected/account/v2/authenticator')
            .then(response => response.data),

    /** Request challenges for the given authentication method. */
    getChallenge: (
        data: ChallengeRequest,
        params: ChallengeRequestParams,
        useAikyamEndpoint = false
    ): Promise<ChallengeResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<ChallengeResponse>('/rest/public/account/v2/authenticator/challenge', data, { params })
            .then(response => response.data),

    /** Provide user response to challenges. */
    attest: (
        data: (PhoneAttestationRequest | QuestionAttestationRequest)[],
        useAikyamEndpoint = false
    ): Promise<AttestationResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .post<AttestationResponse>('/rest/public/account/v2/authenticator/attestation', data)
            .then(response => response.data),

    /** Fetch authenticator methods by the account namers. */
    findAuthenticator: (
        data: AuthenticatorByNamersRequest,
        useAikyamEndpoint = false
    ): Promise<AuthenticatorResponse> =>
        (useAikyamEndpoint ? AikyamClient : WaltersonClient)
            .put<AuthenticatorResponse>('/rest/public/account/v2/authenticator/find', data)
            .then(response => response.data),

    getProfileMetadata: (params: ProfilingMetadataRequestParams): Promise<ProfilingMetadataResponse> =>
        WaltersonClient.get<ProfilingMetadataResponse>('/rest/public/account/v2/profile/metadata', {
            params,
        }).then(response => response.data),

    getAikyamTmxInfoData: (params: AikyamTmxInfoRequestParams): Promise<AikyamTmxInfoResponseData> =>
        AikyamClient.get<AikyamTmxInfoResponseData>('/rest/protected/tmx/info', {
            params,
        }).then(response => response.data),

    // Note: timeoutS is used for both loading of script and profiling independently. Maximum timeout can be double of timeoutS
    rbaProfileWithTimeoutIfNeeded: (data: AikyamTmxInfoResponseData, timeoutS: number): Promise<RBAProfilingResult> => {
        let timeoutHandlerId: ReturnType<typeof setTimeout>;
        let profilingCompletedMessageHandler: (event: MessageEvent<unknown>) => void;

        // Registers message observer for when profiling will finish
        const passed = new Promise<RBAProfilingResult>(resolve => {
            profilingCompletedMessageHandler = event => {
                // event.data will be in format "tmx_profiling_complete:<session_id>"
                // Official documentation: https://portal.threatmetrix.com/kb-en/index.htm#t=implementation%2Fprofiling%2Fprofiling%20features%2Fprofiling_notification_completion.htm&rhsearch=profiling%20notification%20completion&rhhlterm=profiling%20profile%20notification%20completion
                //  Access to that portal can be obtained by reach out to security team (Ex. Huq, Saiful or Roy, Sanjeev)
                if (
                    event.origin === window.location.origin &&
                    typeof event.data === 'string' &&
                    event.data.includes('tmx_profiling_complete:')
                ) {
                    window.removeEventListener('message', profilingCompletedMessageHandler, false);
                    if (timeoutHandlerId) {
                        clearTimeout(timeoutHandlerId);
                    }
                    resolve({ status: 'PASSED' });
                }
            };

            window.addEventListener('message', profilingCompletedMessageHandler, false);
        });
        const buildAwaitForProfilingWithTimeout = () => {
            const timedOut = new Promise<RBAProfilingResult>(resolve => {
                timeoutHandlerId = setTimeout(() => {
                    window.removeEventListener('message', profilingCompletedMessageHandler, false);
                    resolve({ status: 'PROFILING_TIMED_OUT' });
                }, seconds(timeoutS));
            });
            return Promise.race([passed, timedOut]);
        };

        const loadScriptWithTimeout = (url: string): Promise<void | RBAProfilingStatus> => {
            const timeout = new Promise<'SCRIPT_LOAD_TIMED_OUT'>(resolve => {
                timeoutHandlerId = setTimeout(() => {
                    resolve('SCRIPT_LOAD_TIMED_OUT');
                }, seconds(timeoutS));
            });

            return Promise.race([loadScript(url), timeout])
                .then(result => {
                    // No result - indicates script loaded, so we should cancel timeout
                    if (!result) {
                        clearTimeout(timeoutHandlerId);
                    }
                    return result;
                })
                .catch(error => {
                    clearTimeout(timeoutHandlerId);
                    return Promise.reject(error);
                });
        };
        return (
            // Await for script load or timeout
            loadScriptWithTimeout(data.tmxProfileUrl)
                .then(maybeTimeout => {
                    if (maybeTimeout === 'SCRIPT_LOAD_TIMED_OUT') {
                        return { status: maybeTimeout };
                    }
                    // Await for profiling completion or timeout
                    return buildAwaitForProfilingWithTimeout();
                })
                // Handle any unhandled errors
                .catch((error: Error) => Promise.resolve<RBAProfilingResult>({ status: 'ERRORED', error }))
                .then(result => {
                    logger.info(`RBA profiling finished`, result);
                    return result;
                })
        );
    },

    /** Clear the aikyam's session after user logout flag  */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearIDPSessionOnLogOut: (useAikyamEndpoint = true): Promise<AxiosResponse<void>> =>
        AikyamClient.get('/rest/public/account/v2/logout'),
};

export default OBAService;
