import React from 'react';
import type { FC } from 'react';
import { prepend } from 'src/utils/prepend';
import Title from 'src/UI/Title/Title';
import type { TitleProps } from 'src/UI/Title/Title';

import classes from './SampleCards.module.scss';
import type { SampleCardDataEntry, SampleCardsDataWithoutLegends } from './types';

export interface SampleCardsProps {
    cards: SampleCardsDataWithoutLegends;
    headingLevel?: Exclude<TitleProps['headingLevel'], 1 | 2>;
    imgHost: string;
    canShowMultipleColumns?: boolean;
    showSpecificCards?: number[];
}

const SampleCards: FC<SampleCardsProps> = ({
    cards,
    headingLevel = 3,
    imgHost,
    canShowMultipleColumns = false,
    showSpecificCards = [],
}) => (
    <div className={canShowMultipleColumns ? classes.multiColumn : undefined}>
        {Array.from({ length: 6 }, (_, i) => `card${i + 1}`).map((key, i) => {
            const cardData = cards[key as keyof SampleCardsDataWithoutLegends] as SampleCardDataEntry;
            if (!cardData || (showSpecificCards.length > 0 && !showSpecificCards.includes(i))) {
                return null;
            }
            return (
                <div key={cardData.url} className={classes.cardContainer}>
                    <Title size="h6" headingLevel={headingLevel} className="has-rds-mb-8 has-text-centered">
                        {cardData.title}
                    </Title>
                    <img className={classes.cardImg} src={prepend(imgHost)(cardData.url)} alt={cardData.msg} />
                </div>
            );
        })}
    </div>
);

export default SampleCards;
