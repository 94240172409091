import { isAikyamLoginEnabled, isAikyamMFAEnabled } from 'src/features/PortalData';
import { getCookie } from 'src/utils/cookieUtility';
import { TEST_ORIGIN } from 'src/utils/dev';

import type { PortalConfigData } from 'src/features/PortalData';

export const shouldPointToAikyamStage = (): boolean => getCookie('AYPROBE') === '1';

export const shouldUseAikyamLogin = (config: PortalConfigData): boolean =>
    getCookie('ALPROBE') === '1' || isAikyamLoginEnabled(config);

export const shouldPointToAikyamPilotNonProd = (): boolean => getCookie('AYPROBE') === '2';

const mapToAikyamDomain = (domainName: string): string => {
    let aikyamDomainName = `identity.${domainName.replace('www.', '')}`;
    // HSID stage domain points to Aikyam's nonprod domain by default or other Aikyam's domains based on the boolean value.
    if (domainName.includes('st1.')) {
        aikyamDomainName = aikyamDomainName.replace('st1.', '');
        if (shouldPointToAikyamStage()) {
            aikyamDomainName = `stage.${aikyamDomainName}`;
        } else if (shouldPointToAikyamPilotNonProd()) {
            aikyamDomainName = `pilot.nonprod.${aikyamDomainName}`;
        } else {
            aikyamDomainName = `nonprod.${aikyamDomainName}`;
        }
        // HSID test domain points to Aikyam's stage domain
    } else if (domainName.includes('ts1.')) {
        aikyamDomainName = `stage.${aikyamDomainName.replace('ts1.', '')}`;
        // HSID dev domain points to Aikyam's dev domain
    } else if (domainName.includes('dv2.')) {
        aikyamDomainName = `dev.${aikyamDomainName.replace('dv2.', '')}`;
    }
    return aikyamDomainName;
};

/**
 * Generate the Aikyam Service origin from the provided origin
 *
 * @private
 * @param origin The origin to convert
 * @returns The Aikyam origin
 */
export const getAikyamApiHost = (origin: string = TEST_ORIGIN): string =>
    origin.includes('localhost')
        ? origin
        : origin
              .split('//')
              .map((a, i) => (i === 1 ? mapToAikyamDomain(a) : a))
              .join('//');

export const shouldUseAikyamURL = (config: PortalConfigData): boolean => isAikyamMFAEnabled(config);
