import React, { useState, useRef, useContext, lazy, Suspense } from 'react';

import AppStore from 'src/stores/AppStore';
import LoadingBar from 'src/UI/LoadingBar/LoadingBar';
import Banner from 'src/components/PageLayout/subcomponents/Banner/Banner';
import { WebChatContainer } from 'src/components/WebChat/WebChatContainer';
import { wrapComponentName } from 'src/utils/componentUtility';
import { getAEMValue } from 'src/app/AEMContentUtility';
import { ModalDataAttributeIds, useModalState } from 'src/UI/Modal';
import { AemDataAttributes } from 'src/app/Constants';
import { stripBaseSegment } from 'src/utils/urlUtility';
import { logger } from 'src/app/Logger';
import { isNativeApp } from 'src/features/PortalData';
import { QaIds } from 'src/app/QaConstants';

import Header from './subcomponents/Header/Header';
import Footer from './subcomponents/Footer/Footer';

import classes from './PageLayout.module.scss';

const TermsOfUse = lazy(() => import('src/components/ModalForms/TermsOfUse/TermsOfUse'));
const TermsOfUseIframe = lazy(() => import('src/components/ModalForms/TermsOfUseIframe/TermsOfUseIframe'));
const PrivacyPolicy = lazy(() => import('src/components/ModalForms/PrivacyPolicy/PrivacyPolicy'));
const PrivacyPolicyIframe = lazy(() => import('src/components/ModalForms/PrivacyPolicyIframe/PrivacyPolicyIframe'));
const ConsumerNotice = lazy(() => import('src/components/ModalForms/ConsumerNotice/ConsumerNotice'));
const TextingTerms = lazy(() => import('src/components/ModalForms/TextingTerms/TextingTerms'));
const LearnMore = lazy(() => import('src/components/ModalForms/LearnMore/LearnMore'));
const TrustedDevice = lazy(() => import('../ModalForms/TrustedDeviceModal/TrustedDeviceModal'));

const initialBannerState = null;

/**
 * The list of KeyboardEvent values to check for manual keyboard activation of <button>-ish elements.
 *
 * Modern browsers support `KeyboardEvent.code` so {@var buttonActivationKeyboardCodes} lists those. IE11 does not
 * support `KeyboardEvent.code` so we need to use `KeyboardEvent.key` so {@var legacyButtonActivationKeyboardKeys} lists
 * those possible values.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code#browser_compatibility for IE11 lacking `.code`
 *      support
 * @see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#whitespace_keys for `.key`
 *      values.
 */
const buttonActivationKeyboardCodes = ['Enter', 'Space', 'NumpadEnter'];
const legacyButtonActivationKeyboardKeys = ['Enter', 'Spacebar', ' '];

const PageLayout =
    ({ bannerId, shouldLoadWebChat = true, ...boundProps } = {}) =>
    WrappedComponent => {
        const WithLayout = props => {
            const { history } = props;
            const { AEMData, configData } = useContext(AppStore);
            const linkToSkipContent = getAEMValue(AEMData, 'LblSkipToMainContent');
            const modalRef = useRef();
            const [topMostVisibleModal, { showModal, closeModal }] = useModalState();

            // LoadingBar State/API
            const [isLoadingBarVisible, setIsLoadingBarVisible] = useState(false);
            const loadingBarApi = useRef({
                show() {
                    setIsLoadingBarVisible(true);
                },
                hide() {
                    setIsLoadingBarVisible(false);
                },
            });

            // Banner State/API
            const [banner, setBanner] = useState(initialBannerState);
            const bannerApi = useRef({
                show(message) {
                    setBanner(message);
                },
                hide() {
                    setBanner(initialBannerState);
                },
            });

            const handleDelegateContentClicks = ev => {
                const selector = `a[${AemDataAttributes.SHOW_MODAL}], a[${AemDataAttributes.GOTO_FLOW}]`;
                const linkTarget = ev.target.matches(selector) ? ev.target : ev.target.closest(selector);
                if (linkTarget) {
                    // We can assume we have a `dataset` because the `selector` matched
                    const { gotoFlow: flowName, showModal: targetModal } = linkTarget.dataset;
                    // Handle links that normally open in new tabs or but need to show iframe modals instead for native app portals
                    if (!linkTarget.href.includes('#') && linkTarget.target === '_blank' && !!targetModal) {
                        if (isNativeApp(configData)) {
                            ev.preventDefault();
                            showModal(targetModal);
                        }
                        return;
                    }
                    if (flowName) {
                        if (process.env.NODE_ENV !== 'production') {
                            logger.info('Content link detected to navigate to a flow');
                        }
                        history.push(stripBaseSegment(linkTarget.getAttribute('href')));
                    } else if (targetModal) {
                        showModal(targetModal);
                    }
                    ev.preventDefault();
                }
            };

            const handleDelegateContentKeyDowns = ev => {
                // Handle modal content links rewritten to register as buttons for a11y to programmatically re-add
                // keyboard activation for Return/Enter and Spacebar.
                const selector = `a[${AemDataAttributes.SHOW_MODAL}]`;
                // NOTE "buttonish" because we're using `<a role="button">` and not a real <button>
                const buttonishTarget = ev.target.matches(selector) ? ev.target : ev.target.closest(selector);
                if (buttonishTarget) {
                    const { code, key } = ev.nativeEvent;
                    if (
                        // Modern browsers support `event.code`
                        (code && buttonActivationKeyboardCodes.includes(code)) ||
                        // IE11 doesn't support `event.code` but it does support `event.key`
                        (!code && legacyButtonActivationKeyboardKeys.includes(key))
                    ) {
                        buttonishTarget.click();
                        ev.preventDefault();
                    }
                }
            };

            return (
                <>
                    <a href="#main" className={`has-rds-p-8 ${classes.skipLink}`}>
                        {linkToSkipContent}
                    </a>
                    <div
                        className={classes.root}
                        onClick={handleDelegateContentClicks}
                        onKeyDown={handleDelegateContentKeyDowns}
                    >
                        <Header {...boundProps} {...props} />
                        <main id="main" role="main" className="is-relative">
                            <LoadingBar isVisible={isLoadingBarVisible} />
                            <Banner msg={banner} id={bannerId} />

                            <WrappedComponent
                                {...boundProps}
                                {...props}
                                loadingBarApi={loadingBarApi.current}
                                isLoadingBarVisible={isLoadingBarVisible}
                                bannerApi={bannerApi.current}
                            />
                        </main>
                        <Footer {...boundProps} {...props} />

                        {/* Global modal collection */}
                        {topMostVisibleModal === ModalDataAttributeIds.TERMS_OF_USE ||
                        topMostVisibleModal === ModalDataAttributeIds.TERMS_OF_USE_TYPO ? (
                            <Suspense fallback={null}>
                                <TermsOfUse handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.TERMS_OF_USE_IFRAME ? (
                            <Suspense fallback={null}>
                                <TermsOfUseIframe onClose={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.PRIVACY_POLICY ? (
                            <Suspense fallback={null}>
                                <PrivacyPolicy handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.PRIVACY_POLICY_IFRAME ? (
                            <Suspense fallback={null}>
                                <PrivacyPolicyIframe onClose={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.CONSUMER_COMMUNICATIONS ? (
                            <Suspense fallback={null}>
                                <ConsumerNotice handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.TEXTING_TERMS ? (
                            <Suspense fallback={null}>
                                <TextingTerms handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.LEARN_MORE ||
                        topMostVisibleModal === QaIds.LEARN_MORE_MODAL ? (
                            <Suspense fallback={null}>
                                <LearnMore handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}

                        {topMostVisibleModal === ModalDataAttributeIds.TRUSTED_DEVICE ? (
                            <Suspense fallback={null}>
                                <TrustedDevice handleCloseModal={closeModal} modalRef={modalRef} />
                            </Suspense>
                        ) : null}
                    </div>
                    <WebChatContainer shouldLoadWebChat={shouldLoadWebChat} />
                </>
            );
        };

        WithLayout.displayName = wrapComponentName(WrappedComponent, WithLayout);

        return WithLayout;
    };

export default PageLayout;
